/* eslint-disable prefer-arrow-callback */
import './src/styles/index.scss';

// You can delete this file if you're not using it
const onInitialClientRender = () => {
	// if (typeof drift === "object" && window.driftAppId) {
	//   window.drift.SNIPPET_VERSION = "0.3.1"
	//   window.drift.load(window.driftAppId)
	//   window.drift.on("ready", function(api, payload) {
	//     window.driftApi = api
	//   })
	// }
};

export { onInitialClientRender };
